import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";
import Lottie from "lottie-web";

// Connects to data-controller="lottie"
export default class extends Controller {
  static targets = [];

  connect() {
    this.element.innerHTML = "";
    this.lottie = Lottie.loadAnimation({
      animationData: JSON.parse(this.element.dataset.lottie), // the path to the animation json
      autoplay: false,
      container: this.element, // the dom element that will contain the animation
      loop: false,
      renderer: "svg",
    });

    if (this.element.dataset.onscroll) {
      this.observer = new IntersectionObserver(
        (entries, _observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const animTime = this.lottie.totalFrames / this.lottie.frameRate;
              this.play();
              gsap.delayedCall(animTime + 0.05, this.reverse.bind(this));
            }
          });
        },
        {
          // root: null,
          // rootMargin: "0px 0px 0px 0px",
          threshold: 0.8,
        },
      );
      this.observer.observe(this.element);
    }
  }

  disconnect() {
    this.element.innerHTML = "";
    this.lottie.destroy();
    this.observer.disconnect();
  }

  onMouseOut() {
    this.reverse();
  }

  onMouseOver() {
    this.play();
  }

  play() {
    this.lottie.setDirection(1);
    this.lottie.play();
  }

  reverse() {
    this.lottie.setDirection(-1);
    this.lottie.play();
  }
}
