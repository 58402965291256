import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";

import { PlyrLayout, VidstackPlayer } from "vidstack/global/player";
import "vidstack/player/styles/base.css";
import "vidstack/player/styles/plyr/theme.css";

// Connects to data-controller="vidstack"
export default class extends Controller {
  static targets = ["container"];

  connect() {
    this.promise = VidstackPlayer.create({
      target: this.containerTarget,
      src: this.element.dataset.source == "file" ? this.element.dataset.video : this.element.dataset.source + "/" + this.element.dataset.video,
      playsinline: true,
      controls: false,
      layout: new PlyrLayout(),
    });
  }

  disconnect() {
    this.promise?.then((player) => player.destroy());
    this.promise = undefined;
  }

  onPosterClick(event) {
    this.promise?.then((player) => player.play());
    gsap.to(event.currentTarget, { display: "none", duration: 0.5, opacity: 0 });
  }
}
